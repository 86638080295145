<template>
  This is Spinner
</template>

<script>
export default {
  name:'Spinner',
  setup(){

  }
}
</script>

<style>

</style>